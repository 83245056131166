import { initializeApp, getApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";

let firebaseApp;

export const database = (creds) => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(creds);
  } else {
    firebaseApp = getApp(); // Ambil instance yang sudah ada
  }

  return getDatabase(firebaseApp);
};

export const auth = (creds) => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(creds);
  } else {
    firebaseApp = getApp(); // Ambil instance yang sudah ada
  }

  return getAuth(firebaseApp);
};

export const signIn = signInWithCustomToken
