import PropTypes from 'prop-types';
import LottiePlayer from './LottiePlayerApp';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const EmptyWorkerApp = ({ data, creds }) => {
  const mapsCenter = {
    lat: data.lat,
    lng: data.lng
  };

  return (
    <div>
      <LoadScript
        googleMapsApiKey = {creds}
      >
        <GoogleMap
          zoom={16}
          center={mapsCenter}
          mapContainerClassName="h-screen w-full rounded"
          options={{
            disableDefaultUI: true,
            clickableIcons: true,
            draggable: false
          }}
        >

          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border rounded-[20px] shadow-lg p-4 w-10/12 md:w-fit">
            <div>
              <LottiePlayer
                src={`${window.location.origin}/animation-maps.json`}
                style={{ height: '100px', width: '100px' }}
              />
            </div>
            <p className="text-base font-black text-center">
              Menunggu Mitra mengambil pekerjaan
            </p>
            <p className="mt-1 text-sm font-reguler text-center">
              Daftar mitra yang bekerja akan muncul jika <br/> sudah menerima pekerjaan
            </p>
          </div>

          <Marker
            position={mapsCenter}
            icon={{
              url: `${window.location.origin}/marker.png`,
            }}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

EmptyWorkerApp.propTypes = {
  data: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  })
};

export default EmptyWorkerApp