import { useEffect, useState } from "react";
import { database, signIn, auth } from "../configs/firebase";
import { onValue, ref } from "firebase/database";
import { useNavigate, useSearchParams } from "react-router-dom";
import cryptoJS from "crypto-js";

// Components
import Card from "../components/CardApp";
import NewMap from "../components/MapApp";
import Header from "../components/HeaderApp";
import EmptyWorker from "../components/EmptyWorkerApp";

// Services
import trackingService from "../services/tracking.service";

const IndexApp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // STATE
  const [maps, setMaps] = useState({ lat:0, lng: 0 });
  const [worker, setWorker] = useState([]);
  const [isStore, setIsStore] = useState([]);
  const [bangunan, setJenis] = useState(null);
  const [address, setAddress] = useState(null);
  const [isSelected, setSelected] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isParamMaterial, setIsParamMaterial] = useState(false)
  const [creds, setCreds] = useState(null);

  const [responseApi, setData] = useState({
    workers: [],
    customer: {},
  });

  const getFromFirebase = (data, config) => {
    let listWorkers = data.workers;
    listWorkers.forEach((element, i) => {
      const datas = ref(database(config), `/locations/${element.id}`);
      onValue(datas, (snap) => {
        const location = snap.val();
        if (location) {
          setData({
            customer: data.customer,
            workers: listWorkers.map((e) =>
              e.id === element.id
                ? {
                    ...e,
                    latitude: location.lat,
                    longitude: location.lng,
                    is_firebase: true,
                  }
                : e
            ),
          });

          listWorkers = listWorkers.map((e) =>
            e.id === element.id
              ? {
                  ...e,
                  latitude: location.lat,
                  longitude: location.lng,
                  is_firebase: true,
                }
              : e
          );
        } else {
          setData({
            customer: data?.customer,
            workers: listWorkers.map((e) => (e.id === element.id ? e : e)),
          });
        }

        if (i + 1 === listWorkers.length) {
          setIsLoading(false);
        }
      });
    });
  };

  useEffect(() => {
    localStorage.clear();

    const token = searchParams.get("token");
    const projectId = searchParams.get("project_id");
    const workerId = searchParams.get("worker_id");
    const isMaterial = searchParams.get("is_material");

    if (isMaterial) {
      setIsParamMaterial(true)
    }

    if (!projectId || !token) {
      navigate("error");
      return;
    }

    const fetchData = async() => {
      try {
        const payload = {
          project_id: projectId,
          token: token,
          ...(workerId && { worker_id: workerId }),
          ...(isMaterial !== null && { is_material: isMaterial })
        };

        const res = await trackingService.getData(payload);
        if (res.data.code === 1) {
          const { customer, workers, material_stores, config, creds, new_token } = res.data.data

          const newConfig = cryptoJS.AES.decrypt(config.split('-')[1], atob(config.split('-')[0])).toString(cryptoJS.enc.Utf8)
          const newCreds = cryptoJS.AES.decrypt(creds.split('-')[1], atob(creds.split('-')[0])).toString(cryptoJS.enc.Utf8)

          const newAuth = auth(JSON.parse(newConfig))

          await signIn(newAuth, new_token)
          setCreds(newCreds)

          if (customer) {
            setAddress(customer.address);
            setJenis(customer.jenis_bangunan);
            setMaps({
              lat: customer?.latitude || 0,
              lng: customer?.longitude || 0
            });
          }

          if (workers && workers.length > 0) {
            setWorker(workers)
            getFromFirebase(res.data.data, JSON.parse(newConfig));
          } else {
            setIsLoading(false)
          }

          if (material_stores && material_stores.length > 0) {
            setIsStore(material_stores)
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        navigate("error");
        setIsLoading(false);
      }
    }

    fetchData();

  }, [navigate, searchParams]);

  const handleClickMarker = (id) => {
    let index = worker.findIndex((obj) => obj.id === id);

    if (index !== -1) {
      let obj = worker.splice(index, 1)[0];
      worker.unshift(obj);
      setSelected(id);
    }

    if (isStore.length === 0) {
      if (isSelected === id) {
        setIsVisible((prevState) => !prevState)
      } else {
        setSelected(id)
        setIsVisible(true)
      }
    }

    return worker;
  };

  const Loading = () => (
    <div className="flex flex-col justify-center items-center absolute w-full h-full text-center">
      <img src={`${window.location.origin}/loading.svg`} alt="" />
    </div>
  );

  if (isLoading) return <Loading />;

  return (
    <>
      { worker.length === 0 && !isLoading ? (
          <EmptyWorker data={maps} creds={creds}/>
        ) : (
          <div className="top-0 left-0 right-0 bottom-0">
            <main className="">
              <NewMap onClick={handleClickMarker} response={responseApi} store={isStore} creds={creds}/>
            </main>
            { !isParamMaterial && <Header jenisBangunan={bangunan} address={address} /> }
            <div className={`transition-all duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'} ease-in-out`}>
              { isVisible && <Card workers={worker}/> }
            </div>
          </div>
        )
      }
    </>
  );
};

export default IndexApp;
